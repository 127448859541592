.embed-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.post-content {
  img {
    max-width: 100%;
    margin: 0 auto 2.5em;
    display: block;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400&display=swap');

body {
  text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

.post, .post-list {
  a {
    padding: 1px 0 0 0;
    box-shadow: inset 0 -2px 0 #0074e4;
    text-decoration: none;
    background: 0 0;
    transition: box-shadow .2s;
  
    &:hover {
      box-shadow: inset 0 -1.1em 0 rgba(0, 116, 228, .2);
      text-decoration: none;
    }
  }
}

ul li {
  position: relative;
  list-style: none;
  margin-bottom: calc(1.75rem / 2);

  &::before {
    display: inline-block;
    content: ' ';
    background: #0074e4;
    width: 14px;
    height: 5px;
    position: absolute;
    top: 10px;
    left: -26px;
    transform: rotate(-5deg);
  }

  &:nth-of-type(2n+1)::before {
    transform: rotate(4deg);
  }
}

ol > li {
  padding-left: 30px;
  counter-increment: step-counter;
  position: relative;
  list-style: none;
  margin-bottom: calc(1.75rem / 2);
  &::before {
    content: counter(step-counter);
    margin-right: 1em;
    line-height: 1;
    background-color: #0074e4;
    box-shadow: rgba(128, 128, 128, 0.2) 0.2em 0.2em 0px;
    color: rgb(255, 255, 255);
    padding: 2px 0px;
    width: 2.7em;
    height: 1.2em;
    display: block;
    position: absolute;
    box-sizing: border-box;
    margin-left: -62px;
    margin-top: -3px;
    top: 8px;
    text-align: center;
    font-size: 0.9em;
    font-style: normal;
    font-family: sharp-sans, sans-serif;
    font-variant-numeric: lining-nums;
    font-feature-settings: "lnum";
  }
}

.home .post-list, .post {
  .post-link {
    font-size: 1em;
    display: inline;
  }

  .post-meta {
    display: none;
  }
}

.text-primary {
  color: #0074e4;
}

.hidden {
  display: none;
}

.social-media-list {
  li::before {
    display: none;
  }
}

#tag-cloud {
  a::before {
    content: '#'
  }
}

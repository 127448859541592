$base-font-family: 'Lora', serif;
$base-font-size: 20px;
$base-line-height: 1.618;
$brand-color: #3b4252;

$text-color: #111111 !default;
$link-base-color: $text-color;
$link-hover-color: $text-color;
$link-visited-color: darken($link-base-color, 15%);

$content-width: 70 * ($base-font-size / $base-line-height);

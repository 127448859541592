.newsletter-container {
  margin-top: 5rem;
}

.cta-container {
  display: flex;
  align-items: flex-start;

  .book-preview {
    width: 35%;
    margin-left: 1rem;
    display: block;
  }
}

.newsletter-header {
  background-color: #0074e4;
  transform: rotate(-2deg);
  display: inline-block;
  margin-left: 1rem;
}

.newsletter-body {
  margin-top: -2rem;
  background-color: #f6f6f6;
  padding: 3rem 1rem 1rem 1rem;
  border: 1px #0074e4 dashed;
}

.newsletter-header h2 {
  margin: 0;
  padding: 0.75rem 1.5rem 1rem 1rem;
  color: white;
  transform: rotate(2deg);
  display: inline-flex;
  align-items: center;
}

.newsletter-header h2 span {
  margin-left: 0.5rem;
}

.newsletter-body p {
  margin-bottom: 1rem;
}

.newsletter-container {
  .logo {
    width: 50px;
    height: 50px;
    margin-right: 0.5rem;
  }

  input[type="email"] {
    border: 1px solid #ced3d9;
    border-radius: 0.25rem;
    display: block;
    padding: 0.75rem 1rem;
    width: 100%;
    margin-bottom: 1rem;
    box-sizing: border-box;
    font-size: 1rem;
  }
  
  input[type="submit"] {
    display: block;
    border: 0;
    border-radius: 0.25rem;
    color: #fff;
    outline: 0;
    padding: 0.75rem 1rem;
    width: 100%;
    font-size: 1rem;
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 0.5rem;
    background-color: #f154b1;
    margin-top: 1rem;
  }
}

.newsletter-disclaimer {
  text-align: center;
  font-size: 0.833rem;
}

@media (max-width: 42rem) {
  .cta-container {
    .book-preview {
      display: none;
    }
  }

  .newsletter-container {
    text-align: center;
  }

  .newsletter-body {
    text-align: left;
  }

  .newsletter-header {
    margin-left: 0;
  }

  .newsletter-header h2 {
    font-size: 1rem;
  }

  .newsletter-header .logo {
    width: 40px;
    height: 40px;
  }
}
